<template>
	<div class="table">
		<div class="table__title">{{ title }}</div>

		<div v-if="printingActive">
			<div v-if="selectedOrders && selectedOrders.length > 0">
				<ClientOrdersPrint2PDF
					ref="clientOrdersToPdf"
					:orders="selectedOrders"
					:orderlines="orderlines"
					@close="
						() => {
							printingActive = false;
						}
					"
				></ClientOrdersPrint2PDF>
			</div>
		</div>

		<Dialog class="table__dialog" :visible.sync="showClientOrderDialog" :style="{ width: '95vw', maxWidth: '900px' }" position="top" :modal="true">
			<template #header>
				<h3 v-if="currentClientOrder">Order sheet</h3>
			</template>

			<div v-if="currentClientOrder && showClientOrderDialog">
				<ClientOrderDetailsPrint2PDF
					ref="clientOrderToPdf"
					:clientOrder="currentClientOrder"
					:clientOrderLines="getOrderlinesFromDataTable(currentClientOrder.orderlines)"
					@close="
						() => {
							currentClientOrder = null;
							showClientOrderDialog = false;
						}
					"
				></ClientOrderDetailsPrint2PDF>
			</div>

			<template #footer>
				<Button v-if="currentClientOrder" type="button" label="Print to pdf" icon="pi pi-file-pdf" class="p-button-primary" @click="$refs.clientOrderToPdf.printClientOrder()" />
			</template>
		</Dialog>

		<Dialog class="table__dialog" :visible.sync="showMailOrdersDialog" :style="{ 'width': '95vw', 'maxWidth': '600px', 'line-height': '30px' }" position="top" :modal="true">
			<template #header>
				<h3 class="p-0 m-0">Orders to e-mail: {{ selectedOrders.length }}</h3>
			</template>

			<InputText placeholder="E-mail" v-model="mailTo" style="width: 100%" />
			<div class="py-2"><Button :disabled="!mailTo || mailTo == ''" icon="pi pi-envelope" type="button" @click="emailSelectedOrders" :label="'Send'" /></div>
		</Dialog>

		<Dialog class="table__dialog" :visible.sync="showNotesDialog" :style="{ 'width': '95vw', 'maxWidth': '600px', 'line-height': '30px' }" position="top" :modal="true">
			<template #header>
				<h3 class="p-0 m-0">Notes</h3>
			</template>
			<div v-if="selectedOrderLine" class="w-full relative">
				<div v-for="(note, i) in notes" class="flex gap-1 w-full mb-2">
					<div class="flex-grow-1"><InputText placeholder="Note" v-model="notes[i].note" style="width: 100%" /></div>
					<div class="">
						<Button
							type="button"
							class="p-button-danger mr-2"
							v-tooltip.top="'Notes'"
							@click="
								() => {
									notes.splice(i, 1);
								}
							"
							icon="pi pi-trash"
						/>
					</div>
				</div>

				<div class="flex gap-1 w-full mb-2" v-if="!notes || notes.length < 5">
					<div class="flex-grow-1"><InputText placeholder="Note" v-model="newNote" style="width: 100%" /></div>
					<div class="">
						<Button type="button" class="p-button-primary mr-2" v-tooltip.top="'Notes'" @click="addNote(newNote)" icon="pi pi-save" :disabled="!newNote || newNote == ''" />
					</div>
				</div>
			</div>

			<div class="py-2"><Button :icon="saveNoteBusy ? 'pi pi-spin pi-spinner' : 'pi pi-save'" type="button" @click="saveNotes(selectedOrderLine)" :label="'Save'" :disabled="saveNoteBusy || (newNote && newNote.length > 0)" /></div>
		</Dialog>

		<Dialog class="table__dialog" :visible.sync="showDeliverToClientDialog" :style="{ 'width': '95vw', 'maxWidth': '600px', 'line-height': '30px' }" position="top" :modal="true">
			<template #header>
				<h3>Deliver to client: {{ selectedOrderLine.sapcode }} - {{ selectedOrderLine.description }}</h3>
			</template>

			Quantity:
			<InputNumber placeholder="Quantity" v-model="clientQuantity" mode="decimal" :min="0" :max="selectedOrderLine.quantity" class="ml-2" style="width: 6rem" />
			<div class="py-2"><Button :disabled="clientQuantity == 0 || clientQuantity > selectedOrderLine.toDeliver" icon="pi pi-check" type="button" @click="deliverToClient(selectedOrderLine)" :label="'Deliver ' + clientQuantity + ' from ' + selectedOrderLine.toDeliver" /></div>
		</Dialog>

		<Dialog class="table__dialog" :visible.sync="showUseStockDialog" :style="{ 'width': '95vw', 'maxWidth': '900px', 'line-height': '30px' }" position="top" :modal="true">
			<template #header>
				<h3>Pick from stock: {{ selectedOrderLine.sapcode }} - {{ selectedOrderLine.description }}</h3>
			</template>
			<div v-if="selectedOrderLine.rawmaterial && selectedOrderLine.rawmaterial.stock">
				<DataTable :value="filteredAvailableStock(selectedOrderLine.rawmaterial.stock)" class="p-datatable-sm p-datatable-striped">
					<Column field="actions" header="Pick" key="actions">
						<template #body="{ data }">
							<InputNumber placeholder="Quantity" v-model="data.pickQuantity" mode="decimal" :min="0" :max="data.quantity" class="ml-2" style="width: 6rem" />
						</template>
					</Column>
					<Column field="stockNetto" header="Available"> </Column>
					<Column field="date_in" header="Date in" key="date_in">
						<template #body="{ data }">
							<div v-if="data.date_in">
								{{ data.date_in | moment("DD MMMM YYYY") }}
							</div>
						</template>
					</Column>
					<Column field="date_expiration" header="Expiration date">
						<template #body="{ data }">
							<div v-if="data.date_expiration">
								<Badge :severity="data.isExpired ? 'danger' : 'success'" :value="data.date_expiration | moment('DD MMMM YYYY')" />
							</div>
						</template>
					</Column>
				</DataTable>
			</div>

			<div class="p-2"><Button :disabled="pickTotal == 0 || pickTotal > selectedOrderLine.toDeliver" icon="pi pi-check" type="button" @click="useStock(selectedOrderLine)" class="ml-2" :label="'Pick ' + pickTotal + ' from ' + selectedOrderLine.toDeliver" /></div>
		</Dialog>

		<PurchaseOrderDialog :show="showOrderDialog" @hide="showOrderDialog = false" :orderline="selectedOrderLine" :rawmaterial="selectedOrderLine.rawmaterial" position="top" :modal="true" />

		<DataTable
			ref="dt"
			v-if="filters"
			:paginator="true"
			:rows="50"
			responsiveLayout="scroll"
			paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
			:rowsPerPageOptions="[10, 20, 50, 100]"
			class="p-datatable-sm p-datatable-striped datatablebug boxshadow"
			:value="orderlines"
			:groupRowsBy="groupRowsBy"
			rowGroupMode="subheader"
			:rowClass="getRowClass"
			sortMode="single"
			:sortField="sortField"
			:sortOrder="-1"
			:expandableRowGroups="false"
			:expandedRowGroups.sync="expandedRowGroups"
			:expandedRows.sync="expandedRows"
			dataKey="id"
			@row-expand="onRowExpand($event)"
			colspan="14"
			showGridlines
			:filters.sync="filters"
			:globalFilterFields="['orderNumber', 'number', 'rawmaterial.sapcode', 'rawmaterial.name', 'pickStatus', 'description', 'order.comments', 'order.costplace', 'packaging', 'supplier.code', 'userNameTotal']"
		>
			<template #header>
				<div class="flex justify-content-between align-items-center">
					<div class="flex-grow-0 pl-4 hidden">
						<Checkbox id="binary" v-model="allOrdersSelected" :binary="true" @input="toggleSelectAllOrders" />
					</div>
					<div class="flex-grow-0 pl-4">
						<Button type="button" icon="pi pi-file-pdf" :label="selectedOrderNumbers && selectedOrderNumbers.length ? String(selectedOrderNumbers.length) : null" class="p-button-danger p-button-outlined" @click="printingActive = true" :disabled="!selectedOrderNumbers || (selectedOrderNumbers && selectedOrderNumbers.length == 0)" />
					</div>

					<div class="flex-grow-0 pl-4">
						<Button type="button" icon="pi pi-envelope" :label="selectedOrderNumbers && selectedOrderNumbers.length ? String(selectedOrderNumbers.length) : null" class="p-button-primary p-button-outlined" @click="showMailOrdersDialog = true" :disabled="!selectedOrderNumbers || (selectedOrderNumbers && selectedOrderNumbers.length == 0)" />
					</div>
					<div class="flex-grow-0 pl-4">
						<Button type="button" icon="pi pi-file-excel" class="p-button-success p-button-outlined" @click="exportToExcel" />
					</div>
					<div class="flex-grow-1"></div>

					<div class="flex-grow-0">
						<span style="cursor: pointer" @click="setPickStatusFilter('Fulfilled')">
							<Badge :style="{ opacity: filters && filters.pickStatus && filters.pickStatus.value == 'Fulfilled' ? 1 : 0.3 }" class="mr-2" value="Fulfilled" severity="success" />
						</span>
						<span style="cursor: pointer" @click="setPickStatusFilter('Partially picked')">
							<Badge :style="{ opacity: filters && filters.pickStatus && filters.pickStatus.value == 'Partially picked' ? 1 : 0.3 }" class="mr-2" value="Partially picked" severity="warning" />
						</span>
						<span style="cursor: pointer" @click="setPickStatusFilter('New')">
							<Badge :style="{ opacity: filters && filters.pickStatus && filters.pickStatus.value == 'New' ? 1 : 0.3 }" class="mr-2" value="New" severity="danger" />
						</span>
					</div>
					<div class="flex-grow-0 pr-3">
						<Dropdown
							v-model="groupRowsBy"
							showClear
							:options="[
								{ name: 'Group by order nr', value: 'orderNumber' },
								//{ name: 'User', value: 'user_created' },
							]"
							optionLabel="name"
							optionValue="value"
							placeholder="Goup by..."
							class="w-full md:w-14rem"
						/>
					</div>
					<div class="flex-grow-0 pr-3">
						<span class="p-input-icon-left">
							<i class="pi pi-search" />
							<InputText v-model="filters['global'].value" placeholder="Keyword Search" />
						</span>
					</div>
					<div class="flex-grow-0 pr-3"><Button type="button" icon="pi pi-filter-slash" class="p-button-outlined" @click="clearFilters()" /></div>
				</div>
			</template>

			<Column field="orderNumber" header="Order" sortable>
				<template #body="{ data }">
					<div style="text-align: center" v-if="data.order">
						<h4>
							{{ data.orderNumber }} <span class="cursor-pointer text-blue-500" @click="showClientOrder(data.order)"> <i class="pi pi-print"></i></span>
						</h4>
					</div>
				</template>
			</Column>

			<Column field="order.deliverymethod.name" header="Delivery method" sortable>
				<template #body="{ data }"> {{ data.order.deliverymethod.name }} {{ data.order.millingroom ? data.order.millingroom.name : null }} </template>
			</Column>

			<Column field="number" header="#" sortable>
				<template #body="{ data }">
					<div style="text-align: center">
						<h4>{{ data.number }} <i v-if="data.urgent" class="pi pi-clock text-primary" v-tooltip.top="'Urgent'"></i></h4>
					</div>
				</template>
			</Column>

			<Column field="userNameTotal" header="User" key="user" sortable>
				<template #body="{ data }"> {{ data.userNameTotal }} </template>
			</Column>

			<Column field="order.datetime" header="Order date" sortable>
				<template #body="{ data }">
					<div v-if="data.order">{{ data.order.datetime | moment("DD MMMM YYYY") }}</div>
				</template>
			</Column>
			<Column field="sapcode" header="SAP code" sortable>
				<template #body="{ data }">
					{{ data.sapcode }}
				</template>
			</Column>
			<Column field="champcode" header="Champ" sortable>
				<template #body="{ data }">
					{{ data.champcode }}
				</template>
			</Column>
			<Column field="description" header="Material name" key="description" sortable>
				<template #body="{ data }"> {{ data.description }} </template>
			</Column>
			<Column field="packaging" header="Packaging" sortable>
				<template #body="{ data }"> {{ data.packaging }} </template>
			</Column>
			<Column field="weight" header="Weight" sortable>
				<template #body="{ data }">
					<div v-if="data.weight">{{ parseFloat(data.weight).toFixed(2) }} kg</div>
				</template>
			</Column>

			<Column field="supplier.code" header="Order from" key="orderfrom" sortable>
				<template #body="{ data }">
					{{ data.supplier.code }}
				</template>
			</Column>

			<Column field="stockSum" header="On stock">
				<template #body="{ data }">
					<div v-if="data.rawmaterial">{{ data.stockSum }}</div>
				</template>
			</Column>
			<Column field="quantity" header="Quantity" key="quantity"> </Column>

			<Column field="deliveriesSum" header="Delivered">
				<template #body="{ data }">
					{{ data.deliveriesSum }}
				</template>
			</Column>

			<Column field="toDeliver" header="Remaining">
				<template #body="{ data }">
					{{ data.toDeliver }}
				</template>
			</Column>

			<Column field="purchases" header="Purchased">
				<template #body="{ data }">
					<div v-if="filterPurchasesForOrderline(data.purchases)">
						<div v-for="(purchase, pi) in filterPurchasesForOrderline(data.purchases)" :key="pi" style="white-space: nowrap">
							<span v-if="purchase.date" class="text-xs">{{ purchase.date | moment("DD MMMM YYYY") }}: </span>{{ purchase.quantity }}
						</div>
					</div>
				</template>
			</Column>

			<Column field="pickStatus" header="Status" sortable>
				<template #body="{ data }">
					<Badge v-if="data.pickStatus == 'Fulfilled'" :value="data.pickStatus" severity="success" />
					<Badge v-else-if="data.pickStatus == 'Partially picked'" :value="data.pickStatus" severity="warning" />
					<Badge v-else :value="data.pickStatus" severity="danger" />
				</template>
			</Column>

			<Column field="dateFulfilled" header="Fulfilled" sortable>
				<template #body="{ data }">
					<div v-if="data.dateFulfilled">{{ data.dateFulfilled | moment("DD MMMM YYYY") }}</div>
				</template>
			</Column>

			<Column field="Actions" header=" " key="actions">
				<template #body="{ data }">
					<div class="flex p-ai-center">
						<Button type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" v-tooltip.top="'Delete'" @click="deleteOrderline(data)" />
						<div v-if="data.rawmaterial">
							<Button type="button" v-if="1 == 2 && data.stockSum <= 0" icon="pi pi-ban" class="p-button-rounded p-button-danger mr-2" disabled v-tooltip.top="'Out of stock'" />
							<Button type="button" v-if="data.pickStatus != 'Fulfilled' && data.stockSum > 0" icon="pi pi-bars" class="p-button-rounded mr-2 p-button-warning" v-tooltip.top="'Deliver from stock'" @click="openStockDialog(data)" />
							<div v-else v-tooltip.top="'Not available'">
								<Button type="button" icon="pi pi-bars" class="p-button-rounded mr-2 p-button-danger" disabled />
							</div>
						</div>
						<div v-else>
							<div v-if="getPurchasesDelivered(data.purchases) > 0"><Button type="button" icon="pi pi-check-circle" class="p-button-rounded mr-2 p-button-warning" @click="openDeliverToClientDialog(data)" v-tooltip.top="'Deliver to client'" /></div>
							<div v-else-if="getPurchasedQuantity(data.purchases) > 0"><Button type="button" icon="pi pi-check-circle" class="p-button-rounded mr-2 p-button-warning" disabled v-tooltip.top="'Waiting for delivery'" /></div>
							<div v-else><Button type="button" icon="pi pi-check-circle" class="p-button-rounded mr-2 p-button-warning" disabled v-tooltip.top="'Not purchased'" /></div>
						</div>
						<i v-if="data.notes && data.notes.length > 0" v-badge="data.notes ? data.notes.length : null" class="pi pi-bell cursor-pointer" style="font-size: 1.5rem; margin-right: 18px; margin-top: 7px" @click="editNotes(data)" :class="data.notes && data.notes.length > 0 ? 'p-button-primary' : 'p-button-secondary'" />
						<i v-else class="pi pi-bell cursor-pointer" style="font-size: 1.5rem; margin-right: 18px; margin-top: 7px" @click="editNotes(data)" :class="data.notes && data.notes.length > 0 ? 'p-button-primary' : 'p-button-secondary'" />

						<Button v-if="1 == 2 && getPurchasedQuantity(data.purchases) > 0" type="button" label="Purchase" class="p-button-primary" v-tooltip.top="'Purchase'" @click="orderAtSupplier(data)" :badge="String(getPurchasedQuantity(data.purchases))" badgeClass="p-badge-warning" />
						<Button v-else type="button" label="Purchase" class="p-button-primary" v-tooltip.top="'Purchase'" @click="orderAtSupplier(data)" :badge="String(getPurchasedQuantity(data.purchases))" />
					</div>
				</template>
			</Column>
			<template #groupheader="{ data }" v-if="groupRowsBy">
				<div class="-m-2 p-4 bg-gray-100">
					<div class="flex" v-if="groupRowsBy == 'user_created'">
						<div v-if="data.user_created && data.user_created">
							<h2>{{ data.user_created.first_name }} {{ data.user_created.last_name }}</h2>
						</div>
					</div>
					<div class="flex" v-if="groupRowsBy == 'orderNumber'">
						<div class="pr-4">
							<Checkbox name="selectedOrderNumbers" :value="data.orderNumber" v-model="selectedOrderNumbers" @input="allOrdersSelected = allOrdersSelectedComputed" />
						</div>
						<div>
							<div class="mb-2">
								<strong class="text-lg" :class="data.pickStatus == 'Fulfilled' ? 'text-green-500' : 'text-black'"
									>{{ data.orderNumber }} <span class="cursor-pointer text-blue-500" @click="showClientOrder(data.order)"> <i class="pi pi-print"></i></span
								></strong>
							</div>
							<div class="mb-2">
								<Badge v-if="data.order && data.order.deliverymethod && data.order.deliverymethod.name == 'Millingroom' && data.order.millingroom" :value="data.order.deliverymethod.name + ' ' + data.order.millingroom.name" class="mr-2" severity="warning" />
								<Badge v-if="data.order && data.order.deliverymethod && data.order.deliverymethod.name == 'Collect'" :value="data.order.deliverymethod.name" class="mr-2" severity="info" />
							</div>
							<div class="mb-2 text-blue-500" v-if="data.order && data.order.costplace">
								<span class="text-sm">Costplace: {{ data.order.costplace }}</span>
							</div>
							<div class="mb-2 text-blue-500" v-if="data.order && data.order.comments">
								<i class="pi pi-info-circle"></i> <span class="text-sm">{{ data.order.comments }}</span>
							</div>
						</div>
					</div>
				</div>
			</template>
		</DataTable>
		<Toast />
		<ConfirmDialog></ConfirmDialog>
	</div>
</template>

<script>
import { mapState } from "vuex";

import PurchaseOrderDialog from "@/components/PurchaseOrderDialog.vue";
import ClientOrderDetailsPrint2PDF from "@/components/ClientOrderDetailsPrint2PDF.vue";
import ClientOrdersPrint2PDF from "@/components/ClientOrdersPrint2PDF.vue";

import * as XLSX from "xlsx";

export default {
	components: { PurchaseOrderDialog, ClientOrderDetailsPrint2PDF, ClientOrdersPrint2PDF },
	data() {
		return {
			title: "Orders",
			expandedRowGroups: null,
			expandedRows: null,
			showClientOrderDialog: false,
			showOrderDialog: false,
			showUseStockDialog: false,
			showDeliverToClientDialog: false,
			currentClientOrder: null,
			selectedOrderLine: {
				number: "",
				date_created: "",
				user_created: {
					first_name: "",
					last_name: "",
					email: "",
				},
				rawmaterial: {
					stock: [],
				},
			},
			selectedOrder: {
				number: "",
				date_created: "",
				user_created: {
					first_name: "",
					last_name: "",
					email: "",
				},
				order: {
					orderlines: {
						rawmaterials: {
							stock: {
								quantity: Number,
							},
						},
					},
				},
			},
			selectedOrderNumbers: [],
			allOrdersSelected: false,
			printingActive: false,
			clientQuantity: 0,
			filters: null,
			calendarDate: null,
			//groupRowsBy: "orderNumber",
			groupRowsBy: null,
			sortField: "orderNumber",
			showMailOrdersDialog: false,
			mailTo: null,
			showNotesDialog: false,
			notes: null,
			newNote: null,
			saveNoteBusy: false,
			rowClasses: {},
		};
	},
	async mounted() {
		this.clearFilters();
		await this.getData();
		this.assignRowClasses();
		this.expandedRowGroups = this.orders.map((order) => order.number);
	},
	methods: {
		async getData() {
			await this.$store.dispatch("getData");
		},

		assignRowClasses() {
			let currentOrderId = null;
			let currentColorClass = "grey-row";

			let orderlines = _.orderBy(this.orderlines, "orderNumber");
			orderlines.forEach((line) => {
				if (currentOrderId !== line.orderNumber) {
					currentOrderId = line.orderNumber;
					currentColorClass = currentColorClass === "grey-row" ? "white-row" : "grey-row";
				}
				this.rowClasses[line.id] = currentColorClass;
			});
		},
		getRowClass(data) {
			return this.rowClasses[data.id];
		},
		toggleColorClass(currentClass) {
			return currentClass === "bg-blue-500" ? "" : "bg-blue-500";
		},
		showClientOrder(order) {
			let self = this;
			self.currentClientOrder = JSON.parse(JSON.stringify(order));
			self.showClientOrderDialog = true;
		},

		onRowExpand(item) {
			this.selectedOrderLine = item.data;
			this.expandedRows = [];
			this.expandedRows.push(item.data);
		},

		getOrderlinesFromDataTable(orderlines) {
			let self = this;

			let orderlinesFromDataTable = null;
			let orderlineFromDataTable = null;
			if (orderlines && orderlines.length > 0) {
				orderlinesFromDataTable = [];
				orderlines.forEach((orderline) => {
					orderlineFromDataTable = _.find(self.orderlines, {
						id: orderline.id,
					});

					if (orderlineFromDataTable) {
						orderlinesFromDataTable.push(orderlineFromDataTable);
					}
				});
			}
			return orderlinesFromDataTable;
		},
		clearFilters() {
			this.filters = JSON.parse(JSON.stringify(this.filtersDefault));
		},
		setPickStatusFilter(pickStatus) {
			let self = this;
			let filters = JSON.parse(JSON.stringify(self.filters));
			if (filters.pickStatus && filters.pickStatus && filters.pickStatus.value == pickStatus) {
				delete filters.pickStatus;
			} else {
				filters.pickStatus = {
					value: pickStatus,
				};
			}

			self.filters = filters;
		},

		async openDeliverToClientDialog(data) {
			this.clientQuantity = data.toDeliver;
			this.selectedOrderLine = data;
			this.showDeliverToClientDialog = true;
		},
		async deliverToClient(orderline) {
			let self = this;
			let order = null;
			if (orderline && orderline.order && orderline.order.id) {
				//console.log(orderline);
				order = _.find(self.orders, { id: orderline.order.id });
				//console.log(order);

				self.$store.dispatch("addToDeliveries", {
					orderline_id: orderline.id,
					stock_id: null,
					quantity: self.clientQuantity,
				});
			}

			this.$toast.add({
				severity: "success",
				summary: "Delivery!",
				detail: "Delivery submitted!",
				life: 3000,
			});
			this.showDeliverToClientDialog = false;
			await this.getData();

			if (orderline && orderline.order && orderline.order.id) {
				order = _.find(self.orders, { id: orderline.order.id });
				if (order && order.email) {
					let toArray = [{ Email: order.email }];
					let bccArray = [{ Email: "robert@zwaanbrandservices.com" }, { Email: "zomerstorm@proton.me" }];

					await self.emailOrder(order, toArray, bccArray);
				}
			}
		},

		async openStockDialog(data) {
			this.selectedOrderLine = data;
			this.showUseStockDialog = true;
		},
		async useStock(orderline) {
			let self = this;
			if (orderline && orderline.rawmaterial && orderline.rawmaterial.stock && orderline.rawmaterial.stock.length > 0) {
				await orderline.rawmaterial.stock.forEach((stock, index) => {
					if (stock.pickQuantity) {
						self.$store.dispatch("addToDeliveries", {
							orderline_id: orderline.id,
							stock_id: stock.id,
							stock_delivered: stock.delivered,
							quantity: stock.pickQuantity,
						});
					}
				});
			}

			await self.getData();

			let order = null;
			if (orderline && orderline.order && orderline.order.id) {
				order = _.find(self.orders, { id: orderline.order.id });

				if (order && order.email) {
					let toArray = [{ Email: order.email }];
					//let toArray = [{ Email: "zomerstorm@proton.me" }];
					let bccArray = [{ Email: "robert@zwaanbrandservices.com" }, { Email: "zomerstorm@proton.me" }];
					await self.emailOrder(order, toArray, bccArray);
				}
			}

			this.$toast.add({
				severity: "success",
				summary: "Delivery!",
				detail: "Delivered from stock!",
				life: 3000,
			});
			this.showUseStockDialog = false;
			//this.expandedRowGroups = [];
			//this.$router.go();
		},
		async orderAtSupplier(payload) {
			this.selectedOrderLine = JSON.parse(JSON.stringify(payload));
			this.showOrderDialog = true;
		},
		async deleteOrderline(payload) {
			await this.$confirm.require({
				message: "Are you sure you want to delete?",
				header: "Delete",
				icon: "pi pi-exclamation-triangle",
				accept: () => {
					this.$store.dispatch("deleteOrderline", payload);
					this.$toast.add({
						severity: "success",
						summary: "Purchase order",
						detail: "Orderline is deleted!",
						life: 3000,
					});
				},
				reject: () => {},
			});
		},
		getPurchasedQuantity(purchases) {
			//return 0;
			return _.sumBy(purchases, function (purchase) {
				if (!purchase.quantity) {
					purchase.quantity = 0;
				}
				if (!purchase.quantity_received) {
					purchase.quantity_received = 0;
				}
				if (purchase.quantity > purchase.quantity_received) {
					return purchase.quantity;
				} else {
					return 0;
				}
			});
		},
		getPurchasesDelivered(purchases) {
			return purchases && purchases.length > 0 ? purchases.reduce((sum, item) => sum + item.quantity_received, 0) : 0;
		},
		filteredAvailableStock(stock) {
			return _.filter(stock, function (st) {
				return st.stockNetto > 0;
			});
		},
		filterPurchasesForOrderline(purchases) {
			if (purchases) {
				purchases = _.filter(purchases, function (purchase) {
					if (purchase) {
						let quantity = purchase.quantity ? purchase.quantity : 0;
						let quantity_received = purchase.quantity_received ? purchase.quantity_received : 0;

						if (quantity - quantity_received > 0) {
							return true;
						}
					}
				});
			}
			return purchases;
		},
		orderContainsUrgent(order) {
			let self = this;

			let returnValue = false;
			if (order && order.orderlines && self.getOrderlinesFromDataTable(order.orderlines)) {
				self.getOrderlinesFromDataTable(order.orderlines).forEach((orderline) => {
					if (orderline.urgent) {
						returnValue = true;
					}
				});
			}
			return returnValue;
		},

		toggleSelectAllOrders() {
			let self = this;
			let orderNumberArray = [];
			if (self.allOrdersSelected && self.allOrdersSelected && self.orders && self.orders.length > 0) {
				self.orders.forEach((order) => {
					orderNumberArray.push(order.number);
				});
			}
			self.selectedOrderNumbers = orderNumberArray;
		},

		exportToExcel() {
			let self = this;

			let excelData = [];

			if (self.orderlines && self.orderlines.length > 0) {
				let orderlines = _.orderBy(self.orderlines, ["order.number", "number"], ["desc", "desc"]);
				orderlines.forEach((orderline) => {
					let millingrooms = [];
					if (orderline.rawmaterial && orderline.rawmaterial.millingrooms && orderline.rawmaterial.millingrooms.length > 0) {
						orderline.rawmaterial.millingrooms.forEach((room) => {
							if (room && room.millingrooms_id && room.millingrooms_id.name) {
								millingrooms.push(room.millingrooms_id.name);
							}
						});
					}

					let purchased = null;
					if (orderline.purchases && orderline.purchases.length > 0) {
						let purch = self.filterPurchasesForOrderline(orderline.purchases);
						if (purch && purch.length > 0) {
							purchased = [];
							purch.forEach((purchase) => {
								if (purchase && purchase.quantity && purchase.date) {
									purchased.push(self.$moment(purchase.date).format("DD-MM-YYYY") + ": " + purchase.quantity);
								}
							});
						}
					}

					let deliverymethod = orderline.order && orderline.order.deliverymethod ? orderline.order.deliverymethod.name : null;
					if (orderline.order && orderline.order.millingroom && orderline.order.millingroom.name) {
						deliverymethod = deliverymethod + " " + orderline.order.millingroom.name;
					}

					let notes_str = "";
					if (orderline.notes && orderline.notes.length > 0) {
						orderline.notes.forEach((note) => {
							notes_str = notes_str + note.note + "\n";
						});
					}

					excelData.push({
						order_number: orderline.orderNumber,
						deliverymethod: deliverymethod,
						costplace: orderline.order && orderline.order.costplace ? orderline.order.costplace : null,
						comments: orderline.order && orderline.order.comments ? orderline.order.comments : null,
						notes: notes_str,
						user: orderline.user_created ? orderline.user_created.first_name + " " + orderline.user_created.last_name : null,
						date: orderline.order && orderline.order.datetime ? self.$moment(orderline.order.datetime).format("DD-MM-YYYY") : null,
						number: orderline.number,
						sap: orderline.sapcode,
						champcode: orderline.champcode,
						description: orderline.description,
						packaging: orderline.packaging,
						weight: orderline.weight,
						supplier: orderline.supplier ? orderline.supplier.code : null,
						//millingrooms: millingrooms.length > 0 ? millingrooms.join(", ") : null,
						quantity: orderline.quantity,
						onstock: orderline.rawmaterial ? orderline.stockSum : null,
						delivered: orderline.deliveriesSum,
						remaining: orderline.toDeliver,
						dateFulfilled: orderline.dateFulfilled ? self.$moment(orderline.dateFulfilled).format("DD-MM-YYYY") : null,
						purchased: purchased && purchased.length > 0 ? purchased.join(", ") : null,
						status: orderline.pickStatus,
					});
				});

				// Convert data to worksheet
				const worksheet = XLSX.utils.json_to_sheet(excelData);

				// Create a new workbook
				const workbook = XLSX.utils.book_new();

				// Append the worksheet to the workbook
				XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

				// Generate an Excel file and trigger the download
				XLSX.writeFile(workbook, "Orders.xlsx");
			}
		},
		async emailSelectedOrders() {
			let self = this;

			//console.log(self.selectedOrders);

			for (let i = 0; i < self.selectedOrders.length; i++) {
				await self.emailOrder(self.selectedOrders[i]);
			}

			this.$toast.add({
				severity: "success",
				summary: "Email",
				detail: "Send succesfully!",
				life: 3000,
			});

			self.showMailOrdersDialog = false;
		},
		async emailOrder(order, toArray, bccArray) {
			let self = this;
			//console.log("order", order);
			if ((!self.mailTo && (!toArray || !bccArray)) || !order || !order.number) {
				return;
			}

			order = JSON.parse(JSON.stringify(order));

			let orderlines = order.orderlines;

			for (let i = 0; i < orderlines.length; i++) {
				let rawmaterial = orderlines[i].rawmaterial ? _.find(this.$store.state.data.rawmaterials, { id: orderlines[i].rawmaterial }) : null;

				orderlines[i].urgent = orderlines[i].urgent ? "Yes" : "No";
				orderlines[i].location = rawmaterial && rawmaterial.location ? rawmaterial.location : "";
				orderlines[i].suppliercode = orderlines[i].supplier && orderlines[i].supplier.code ? orderlines[i].supplier.code : "";
				orderlines[i].hasNotes = orderlines[i].notes && orderlines[i].notes.length > 0;
				orderlines[i].notes = orderlines[i].notes && orderlines[i].notes.length > 0 ? orderlines[i].notes : [];
			}

			let email_subject = "PPG Raw Materials - Order " + order.number;

			let email_variables = {
				order: {
					number: order.number,
					date: order.datetime ? self.$moment(order.datetime).format("DD-MM-YYYY") : "",
					name: order.name || "",
					email: order.email || "",
					deliverymethod: order.deliverymethod ? order.deliverymethod.name || "" : "",
					deliverymethod_title: order.deliverymethod ? order.deliverymethod.title || "" : "",
					deliverymethod_description: order.deliverymethod ? order.deliverymethod.description || "" : "",
					millingroom: order.millingroom ? order.millingroom.name || "" : "",
					costplace: order.costplace || "",
					comments: order.comments || "",
				},
				orderlines: orderlines,
			};

			//console.log(email_variables);
			//return;

			if (self.$directus && self.$directus.auth && self.$directus.auth.token) {
				await self.$axios.post(
					self.$store.state.DIRECTUS_URL + "mail/order",
					{
						to: toArray ? toArray : [{ Email: self.mailTo }],
						bcc: bccArray ? bccArray : null,
						subject: email_subject,
						variables: email_variables,
					},
					{
						headers: {
							Authorization: "Bearer " + self.$directus.auth.token,
						},
					}
				);
			}
		},
		editNotes(orderline) {
			let self = this;
			self.selectedOrderLine = orderline;
			self.notes = orderline.notes && orderline.notes.length > 0 ? JSON.parse(JSON.stringify(orderline.notes)) : null;
			self.showNotesDialog = true;
		},
		addNote(note) {
			let self = this;

			if (note && note.length > 0) {
				self.notes = self.notes ? self.notes : [];
				self.notes.push({ note: note });
				self.newNote = null;
			}
		},
		async saveNotes(orderline) {
			let self = this;
			self.saveNoteBusy = true;
			if (orderline && orderline.id) {
				await self.$store.dispatch("saveOrderlineNotes", {
					orderline_id: orderline.id,
					notes: self.notes,
				});
			}
			await this.getData();

			let order = null;
			if (orderline && orderline.order && orderline.order.id) {
				order = _.find(self.orders, { id: orderline.order.id });

				if (order && order.email) {
					let toArray = [{ Email: order.email }];
					let bccArray = [{ Email: "robert@zwaanbrandservices.com" }, { Email: "zomerstorm@proton.me" }];
					await self.emailOrder(order, toArray, bccArray);
				}
			}

			self.saveNoteBusy = false;
			self.showNotesDialog = false;
		},
	},
	computed: {
		...mapState({
			orders: (state) => state.orders,
			orderlines: (state) => state.orderlines,
			rawmaterials: (state) => state.data.rawmaterials,
			deliveries: (state) => state.deliveries,
			purchases: (state) => state.data.purchases,
			pickTotal() {
				let self = this;
				let sum = 0;
				if (self.selectedOrderLine && self.selectedOrderLine.rawmaterial && self.selectedOrderLine.rawmaterial.stock && self.selectedOrderLine.rawmaterial.stock.length > 0) {
					self.selectedOrderLine.rawmaterial.stock.forEach((item) => {
						if (item.pickQuantity) {
							sum = sum + item.pickQuantity;
						}
					});
				}
				return sum;
			},

			filtersDefault() {
				return {
					global: {
						value: null,
					},
				};
			},

			allOrdersSelectedComputed() {
				let self = this;

				if (self.selectedOrderNumbers && self.orders && self.orders.length == self.selectedOrderNumbers.length) {
					return true;
				}
				return false;
			},
			selectedOrders() {
				let self = this;
				let selectedOrders = [];
				if (self.selectedOrderNumbers && self.selectedOrderNumbers.length > 0 && self.orders && self.orders.length > 0) {
					selectedOrders = _.filter(self.orders, (order) => {
						return _.includes(self.selectedOrderNumbers, order.number);
					});
				}
				return selectedOrders;
			},
		}),
	},

	watch: {
		groupRowsBy(newVal) {
			if (newVal === "orderNumber") {
				this.sortField = "number";
			} else {
				this.sortField = "orderNumber";
			}
		},
	},
};
</script>

<style>
.grey-row {
	background: #f0f0f0 !important;
}
.white-row {
	background: #ffffff !important;
}
</style>
