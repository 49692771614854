var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('div',{staticClass:"table__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"p-4 mb-6 bg-white rounded-lg"},[_c('Chart',{staticClass:"h-30rem",attrs:{"type":"bar","data":_vm.chartData,"options":_vm.chartOptions}})],1),_c('Dialog',{staticClass:"table__dialog",style:({ 'width': '95vw', 'maxWidth': '900px', 'line-height': '30px' }),attrs:{"visible":_vm.showPurchasesDialog,"position":"top","modal":true},on:{"update:visible":function($event){_vm.showPurchasesDialog=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_c('h3',[_vm._v(" Purchases "),(_vm.purchasesActive && _vm.purchasesActive.length > 0 && _vm.purchasesActive[0].supplier)?_c('span',{staticClass:"font-light"},[_vm._v(_vm._s(_vm.purchasesActive[0].supplier.name))]):_vm._e()])])]},proxy:true}])},[(_vm.purchasesActive && _vm.purchasesActive.length > 0 && _vm.purchasesActive[0].supplier)?_c('div',[_c('DataTable',{staticClass:"p-datatable-sm p-datatable-striped",attrs:{"value":_vm.purchasesActive},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticStyle:{"text-align":"left","padding":"0px 10px 0px 10px"}},[_c('download-excel',{staticClass:"p-button p-component",attrs:{"data":_vm.purchasesActiveForExcel,"name":'purchases_' + _vm.purchasesActive[0].supplier.code + '_' + _vm.$moment().format('YYYY-DD-MM hh-mm-ss') + '.csv',"type":"csv"}},[_c('i',{staticClass:"pi pi-file-excel"}),_vm._v(" Export (.csv) ")])],1)]},proxy:true}],null,false,705161500)},[_c('Column',{attrs:{"field":"supplier","header":"Supplier name"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [(data.supplier && data.supplier.name)?_c('div',[_vm._v(" "+_vm._s(data.supplier.name)+" ")]):_vm._e()]}}],null,false,2144414819)}),_c('Column',{attrs:{"field":"supplier","header":"Supplier code"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [(data.supplier && data.supplier.name)?_c('div',[_vm._v(" "+_vm._s(data.supplier.code)+" ")]):_vm._e()]}}],null,false,2027786985)}),_c('Column',{attrs:{"field":"supplier","header":"Supplier GNL"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [(data.supplier && data.supplier.name)?_c('div',[_vm._v(" "+_vm._s(data.supplier.gnl)+" ")]):_vm._e()]}}],null,false,3545383169)}),_c('Column',{attrs:{"field":"number","header":"Number"}}),_c('Column',{attrs:{"field":"orderline","header":"OrderNumber"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [(data.orderline)?_c('div',[_vm._v(" "+_vm._s(data.orderline.orderNumber)+" ")]):_vm._e()]}}],null,false,191177674)}),_c('Column',{key:"date",attrs:{"field":"date","header":"Date"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [(data.date)?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(data.date,"DD MMMM YYYY"))+" ")]):_vm._e()]}}],null,false,978039308)}),_c('Column',{key:"label",attrs:{"field":"label","header":"Delivery"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [(data.label)?_c('div',[_vm._v(" "+_vm._s(data.label)+" ")]):_vm._e()]}}],null,false,1876502153)}),_c('Column',{key:"label",attrs:{"field":"label","header":"Comments"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [(data.comments)?_c('div',[_vm._v(_vm._s(data.comments))]):_vm._e()]}}],null,false,775590537)})],1)],1):_vm._e()]),_c('DataTable',{ref:"dt",staticClass:"p-datatable-sm p-datatable-striped",attrs:{"paginator":true,"rows":20,"responsiveLayout":"scroll","paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown","rowsPerPageOptions":[10, 20, 50],"value":_vm.suppliersData,"sortMode":"single","sortOrder":1,"showGridlines":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticStyle:{"text-align":"left","padding":"0px 10px 0px 10px"}},[_c('download-excel',{staticClass:"p-button p-component",attrs:{"data":_vm.suppliersDataForExcel,"name":'statistics_' + _vm.$moment().format('YYYY-DD-MM hh-mm-ss') + '.csv',"type":"csv"}},[_c('i',{staticClass:"pi pi-file-excel"}),_vm._v(" Export (.csv) ")])],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"table__footer"},[_c('strong',[_vm._v("For questions please contact:")]),_vm._v(" RDA Warehouse | "),_c('strong',[_vm._v("E:")]),_vm._v(" "),_c('a',{attrs:{"href":"mailto:SR0734@ppg.com"}},[_vm._v(" SR0734@ppg.com")]),_vm._v(" |"),_c('strong',[_vm._v(" P:")]),_vm._v(" +31 20 407 53 12")])]},proxy:true}])},[_c('Column',{key:"select",attrs:{"field":"select","header":"Select","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"p-field-checkbox mt-3"},[_c('Checkbox',{attrs:{"id":"binary","binary":true}})],1)]},proxy:true}])}),_c('Column',{key:"name",attrs:{"field":"name","header":"Supplier"}}),_c('Column',{key:"code",attrs:{"field":"code","header":"Code"}}),_c('Column',{key:"gnl",attrs:{"field":"gnl","header":"GNL"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.gnl)+" ")]}}])}),_c('Column',{key:"purchases",attrs:{"field":"purchases","header":"Purchases"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.purchases.length)+" ")]}}])}),_c('Column',{key:"purchasesComplete",attrs:{"field":"purchasesComplete","header":"Complete"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.purchasesComplete.length)+" ")]}}])}),_c('Column',{key:"purchasesIncomplete",attrs:{"field":"purchasesIncomplete","header":"Incomplete"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('span',{staticClass:"p-badge p-component p-badge-warning"},[_vm._v(_vm._s(data.purchasesIncomplete.length))])]}}])}),_c('Column',{key:"purchasesInTime",attrs:{"field":"purchasesInTime","header":"In time"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('span',{staticClass:"p-badge p-component p-badge-success"},[_vm._v(_vm._s(data.purchasesInTime.length))])]}}])}),_c('Column',{key:"purchasesToLate",attrs:{"field":"purchasesToLate","header":"Too late"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('span',{staticClass:"p-badge p-component p-badge-danger",on:{"click":function($event){return _vm.showPurchases(data.purchasesToLate)}}},[_vm._v(_vm._s(data.purchasesToLate.length))])]}}])}),_c('Column',{key:"actions",attrs:{"field":"Actions","header":" "},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"flex p-ai-center"},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Show orders'),expression:"'Show orders'",modifiers:{"top":true}}],staticClass:"p-button-rounded mr-2",attrs:{"type":"button","icon":"pi pi-align-justify"},on:{"click":function($event){return _vm.showPurchases(data)}}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }