import Vue from "vue";
import Vuex from "vuex";
import { Directus, ItemsHandler } from "@directus/sdk";
import router from "../router";
//import createPersistedState from "vuex-persistedstate";
import VuexPersistence from "vuex-persist";
import localForage from "localforage";
import axios from "axios";

const vuexLocal = new VuexPersistence({
	//storage: window.localStorage,

	storage: {
		getItem: async (key) => {
			const raw = (await localForage.getItem(key)) || null;
			const data = raw ? JSON.parse(raw) : {};
			return data;
		},
		setItem: async (key, value) => {
			const valueString = JSON.stringify(value);
			localForage.setItem(key, valueString);
		},
		removeItem: async (key) => localForage.removeItem(key),
	},
	asyncStorage: true,
});

const directus = new Directus("https://ppg-rawmaterials-api.brndcntrl.com/");

import _ from "lodash";
import moment from "moment";

Vue.use(Vuex);

const asyncForEach = async (array, callback) => {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index);
	}
};

const store = new Vuex.Store({
	state: {
		DIRECTUS_URL: "https://ppg-rawmaterials-api.brndcntrl.com/",
		DIRECTUS_URL_ASSETS: "https://ppg-rawmaterials-api.brndcntrl.com/assets/",
		isAuthenticated: false,
		data: {
			rawmaterials: [],
			files: [],
			ghs: [],
			rawmaterials_ghs: [],
			deliverymethods: [],
			hazardstatements: [],
			millingrooms: [],
			suppliers: [],
			stock: [],
			purchases: [],
		},
		cart: [],
		user: {},
		orderforsupplier: {},
	},
	actions: {
		async login({ commit, dispatch }, payload) {
			try {
				return await directus.auth.login(payload).then(() => {
					commit("SET_AUTHENTICATION", true);
					dispatch("setUser");
					router.push("/");
				});
			} catch (error) {
				return "error";
			}
		},
		async passwordRequest({ commit, dispatch }, email) {
			try {
				email = email ? email.toLowerCase() : "";
				return await directus.auth.password.request(email, "https://ppg-rawmaterials.brndcntrl.com/resetpassword?query=" + email).then((response) => {
					commit("SET_AUTHENTICATION", false);
					commit("SET_USER", null);
					return "success";
				});
			} catch (error) {
				return "error";
			}
		},
		async passwordReset({ commit, dispatch }, payload) {
			try {
				return await directus.auth.password.reset(payload.token, payload.password).then((response) => {
					return "success";
				});
			} catch (error) {
				return "error";
			}
		},
		async createUser({ commit, dispatch }, payload) {
			if (payload) {
				payload = _.omit(payload, "password2");
				if (payload.email) {
					payload.email = payload.email.toLowerCase();
				}
			}

			return await axios
				.post("https://ppg-rawmaterials-api.brndcntrl.com/" + "users", payload, {})
				.then((response) => {
					//console.log("response", response);
					if (response && response.data) {
						return response.data;
					}
				})
				.catch((error) => {
					//console.log("catch", error.response.data);
					return error && error.response && error.response.data ? error.response.data : error || null;
					//return error && error.message ? error.message : "An error occured";
				});
		},
		async logout({ commit }) {
			localStorage.clear();
			commit("SET_AUTHENTICATION", false);
			location.reload();
		},
		async setUser({ commit }) {
			let user = await directus.users.me.read({
				fields: ["*", "*.*", "role.*"],
			});
			//console.log(user.role);
			commit("SET_USER", user);
		},
		async checkToken({ commit }) {
			let tokenValid = await directus.auth
				.refresh()
				.then(() => {
					commit("SET_AUTHENTICATION", true);
				})
				.catch(() => {
					commit("SET_AUTHENTICATION", false);
				});
			return tokenValid;
		},

		async getData({ commit, state }) {
			//console.log("getData");
			//console.log(state.user);

			if (state.isAuthenticated) {
				//try {
				const deliverymethods = await directus.items("deliverymethods").readByQuery();

				const millingrooms = await directus.items("millingrooms").readByQuery();

				const rawmaterials = await directus.items("rawmaterials").readByQuery({
					limit: -1,
					fields: ["*", "ghs.GHS_id.name", "ghs.GHS_id.image.id", "stock.*", "millingrooms.millingrooms_id.*"],
				});
				if (rawmaterials && rawmaterials.data) {
					await asyncForEach(rawmaterials.data, async (rawmaterial) => {
						let stockSum = 0;
						if (rawmaterial.stock && rawmaterial.stock.length > 0) {
							stockSum = _.sumBy(rawmaterial.stock, "quantity") - _.sumBy(rawmaterial.stock, "delivered");
						}
						rawmaterial.stockSum = stockSum;
						rawmaterial.stockSum2 = stockSum;
					});
				}
				const suppliers = await directus.items("suppliers").readByQuery({ limit: -1 });
				const orders = await directus.items("orders").readByQuery({
					limit: -1,
					fields: ["*", "order.deliverymethod.*", "order.millingroom.*"],
					// filter:
					// 	state.user && state.user.role && state.user.role.id === "b079aa7f-f524-482f-8dbf-19f26478a64a"
					// 		? {
					// 				user: {
					// 					_eq: state.user.id,
					// 				},
					// 		  }
					// 		: null,
				});

				const purchases = await directus.items("purchases").readByQuery({
					limit: -1,
					fields: ["*"],
				});

				if (purchases && purchases.data) {
					await asyncForEach(purchases.data, async (purchase) => {
						//orderline.datetime = new Date(orderline.datetime);

						if (rawmaterials && rawmaterials.data && rawmaterials.data.length > 0) {
							purchase.rawmaterial = _.find(rawmaterials.data, { id: purchase.rawmaterial }) || null;
						}
						if (suppliers && suppliers.data && suppliers.data.length > 0) {
							purchase.supplier = _.find(suppliers.data, { id: purchase.supplier }) || null;
						}

						if (!purchase.quantity) {
							purchase.quantity = 0;
						}
						if (!purchase.quantity_received) {
							purchase.quantity_received = 0;
						}

						purchase.toDeliver = purchase.quantity - purchase.quantity_received;

						let receiveStatus = "New";
						if (purchase.quantity <= purchase.quantity_received) {
							receiveStatus = "Fulfilled";
						} else if (purchase.quantity > purchase.quantity_received && purchase.quantity_received > 0) {
							receiveStatus = "Partially received";
						}
						purchase.receiveStatus = receiveStatus;

						let purpose = "Client";
						if (purchase.rawmaterial) {
							purpose = "Stock";
						}
						purchase.purpose = purpose;
					});
				}

				const deliveries = await directus.items("deliveries").readByQuery({
					limit: -1,
					fields: ["*"],
				});

				const orderlines = await directus.items("orderlines").readByQuery({
					limit: -1,
					//fields: ["*", "order.*", "order.orderlines.*", "order.orderlines.deliveries.deliveries_id.*", "order.orderlines.rawmaterial.*", "order.orderlines.rawmaterial.stock.*", "order.deliverymethod.*", "order.millingroom.*", "user_created.*", "rawmaterial.*", "supplier.*", "rawmaterial.millingrooms.millingrooms_id.*", "deliveries.deliveries_id.*", "purchases.*"],
					fields: ["*", "user_created.*", "supplier.*", "deliveries.deliveries_id"],
					filter: {
						order: { _nnull: true },
					},
					// filter:
					// 	state.user && state.user.role && state.user.role.id === "b079aa7f-f524-482f-8dbf-19f26478a64a"
					// 		? {
					// 				order: {
					// 					user: {
					// 				""		_eq: state.user.id,
					// 					},
					// 				},
					// 		  }
					// 		: null,
				});

				if (orderlines && orderlines.data && orderlines.data.length > 0) {
					await asyncForEach(orderlines.data, async (orderline) => {
						orderline.userNameTotal = orderline.user_created && orderline.user_created.first_name ? orderline.user_created.first_name + " " + orderline.user_created.last_name : "";
						if (orders && orders.data && orders.data.length > 0) {
							orderline.order = _.find(orders.data, { id: orderline.order });
						}
						if (rawmaterials && rawmaterials.data && rawmaterials.data.length > 0) {
							orderline.rawmaterial = _.find(rawmaterials.data, { id: orderline.rawmaterial });
						}
						orderline.sapcode = orderline.sapcode ? orderline.sapcode : orderline.rawmaterial ? orderline.rawmaterial.sapcode : "";
						orderline.champcode = orderline.champcode ? orderline.champcode : orderline.rawmaterial ? orderline.rawmaterial.champcode : "";

						if (orderline.purchases && orderline)
							if (orderline.purchases && orderline.purchases.length > 0 && purchases && purchases.data && purchases.data.length > 0) {
								orderline.purchases = JSON.parse(
									JSON.stringify(
										_.filter(purchases.data, (purchase) => {
											return orderline.purchases.indexOf(purchase.id) >= 0;
										})
									)
								);
							}

						if (orderline.deliveries && orderline.deliveries.length > 0 && deliveries && deliveries.data && deliveries.data.length > 0) {
							_.each(orderline.deliveries, (item) => {
								let foundDelivery = _.find(deliveries.data, { id: item.deliveries_id });
								if (foundDelivery) {
									item.deliveries_id = foundDelivery;
								}
							});
						}

						if (orderline && orderline.order && orderline.order.number) {
							//orderline.datetime = new Date(orderline.datetime);

							orderline.orderNumber = orderline.order.number;
						} else {
							orderline.orderNumber = null;
						}
						let stockSum = 0;
						if (orderline && orderline.rawmaterial && orderline.rawmaterial.stock && orderline.rawmaterial.stock.length > 0) {
							stockSum = _.sumBy(orderline.rawmaterial.stock, "quantity") - _.sumBy(orderline.rawmaterial.stock, "delivered");
							await asyncForEach(orderline.rawmaterial.stock, async (item, index2) => {
								if (!item.quantity) {
									item.quantity = 0;
								}
								if (!item.delivered) {
									item.delivered = 0;
								}
								let stockNetto = item.quantity - item.delivered;
								orderline.rawmaterial.stock[index2].stockNetto = stockNetto;

								let date_expiration = null;
								let isExpired = false;

								if (orderline.rawmaterial.expiration_days && item.date_in) {
									date_expiration = moment(item.date_in).add(orderline.rawmaterial.expiration_days, "days");
									if (moment() >= moment(date_expiration)) {
										isExpired = true;
									}
								}
								orderline.rawmaterial.stock[index2].date_expiration = date_expiration;
								orderline.rawmaterial.stock[index2].isExpired = isExpired;
							});
						}
						orderline.stockSum = stockSum;

						let deliveriesSum = 0;
						if (orderline && orderline.deliveries && orderline.deliveries.length > 0) {
							deliveriesSum = _.sumBy(orderline.deliveries, "deliveries_id.quantity");
						}
						//if (orderline.number == "1000316") console.log("deliveriesSum", orderline.deliveries);
						orderline.deliveriesSum = deliveriesSum;

						if (!orderline.deliveriesSum || isNaN(orderline.deliveriesSum)) {
							orderline.deliveriesSum = 0;
						}

						if (!orderline.quantity || isNaN(orderline.quantity)) {
							orderline.quantity = 0;
						}

						orderline.toDeliver = orderline.quantity - orderline.deliveriesSum;

						if (!orderline.toDeliver || isNaN(orderline.toDeliver)) {
							orderline.toDeliver = 0;
						}

						let pickStatus = "New";
						if (orderline.quantity <= orderline.deliveriesSum) {
							pickStatus = "Fulfilled";
						} else if (orderline.quantity > orderline.deliveriesSum && orderline.deliveriesSum > 0) {
							pickStatus = "Partially picked";
						}
						orderline.pickStatus = pickStatus;

						let dateFulfilled = null;
						if (pickStatus == "Fulfilled" && orderline.deliveries && orderline.deliveries.length > 0) {
							let latestDelivery = _.maxBy(orderline.deliveries, (delivery) => (delivery && delivery.deliveries_id && delivery.deliveries_id.datetime ? new Date(delivery.deliveries_id.datetime) : null));
							dateFulfilled = latestDelivery && latestDelivery.deliveries_id ? latestDelivery.deliveries_id.datetime : null;
						}

						orderline.dateFulfilled = dateFulfilled;
					});
				}

				if (orders && orders.data && orders.data.length > 0) {
					await asyncForEach(orders.data, async (order) => {
						if (order.orderlines && order.orderlines.length > 0 && orderlines && orderlines.data && orderlines.data.length > 0) {
							order.orderlines = JSON.parse(
								JSON.stringify(
									_.filter(orderlines.data, (orderline) => {
										return order.orderlines.indexOf(orderline.id) >= 0;
									})
								)
							);
						}
						if (order.millingroom && millingrooms && millingrooms.data && millingrooms.data.length > 0) {
							order.millingroom = _.find(millingrooms.data, { id: order.millingroom });
						}
						if (order.deliverymethod && deliverymethods && deliverymethods.data && deliverymethods.data.length > 0) {
							order.deliverymethod = _.find(deliverymethods.data, { id: order.deliverymethod });
						}
					});
				}

				const stock = await directus.items("stock").readByQuery({
					limit: -1,
					fields: ["*", "deliveries.*"],
				});

				/*
						purchases.data = _.filter(purchases.data, function (purchase) {
							console.log(purchase.receiveStatus);
							return purchase.toDeliver > 0;
						});
						*/

				commit("SET_RAWMATERIALS", rawmaterials);
				commit("SET_DELIVERYMETHODS", deliverymethods);
				commit("SET_ORDERS", orders);
				commit("SET_ORDERLINES", orderlines);
				commit("SET_SUPPLIERS", suppliers);
				commit("SET_STOCK", stock);
				commit("SET_PURCHASES", purchases);
				commit("SET_MILLINGROOMS", millingrooms);
				// } catch (err) {
				// 	console.log("error get data");
				// 	console.log(err);
				// 	//commit("SET_AUTHENTICATION", false);
				// }
			} else {
				//router.push("/login");
			}
		},

		addToCart({ commit, state }, payload) {
			let objIndex = state.cart.findIndex((obj) => obj.id && obj.id > 0 && obj.id == payload.id);
			if (objIndex !== -1) {
				state.cart[objIndex].quantityToAdd += Number(payload.quantityToAdd);
			} else {
				commit("ADD_TO_CART", { datetime: new Date(), ...payload });
			}
		},
		removeFromCart({ commit }, payload) {
			commit("REMOVE_FROM_CART", payload);
		},
		async addToDeliveries({ commit, dispatch, state }, payload) {
			directus
				.items("deliveries")
				.createOne({
					quantity: payload.quantity,
					stock: payload.stock_id,
					comments: payload.comments,
				})
				.then((response) => {
					let delivery = response;

					directus
						.items("orderlines_deliveries")
						.createOne({
							orderlines_id: payload.orderline_id,
							deliveries_id: delivery.id,
						})
						.then((response) => {
							if (!payload.stock_delivered) {
								payload.stock_delivered = 0;
							}
							if (payload.stock_id) {
								directus
									.items("stock")
									.updateOne(payload.stock_id, {
										delivered: payload.stock_delivered + payload.quantity,
									})
									.then((response) => {
										dispatch("getData");
									});
							}
						});
				});
		},

		async submitStockOrder({ commit, dispatch, state }, payload) {
			let orderNumber = await dispatch("getOrderNumber");
			let orderlineNumber = await dispatch("getOrderlineNumber");

			let createdOrder = await directus.items("orders").createOne({
				number: orderNumber,
				datetime: new Date(),
				...payload,
				...state.cart,
			});

			let tempOrderLines = state.cart.map((item) => {
				item.order = createdOrder.id;
				item.rawmaterial = item.id;
				item.quantity = item.quantityToAdd;
				delete item.id;
				delete item.quantityToAdd;
				return item;
			});

			await asyncForEach(tempOrderLines, async (orderline, index) => {
				orderline.number = orderlineNumber;
				if (orderline.supplier && orderline.supplier.id) {
					tempOrderLines[index].supplier = orderline.supplier.id;
				}
				orderlineNumber = orderlineNumber + 1;
			});

			let orderlinesResponse = await directus.items("orderlines").createMany([...tempOrderLines]);

			//console.log(orderlinesResponse);

			dispatch("getData");

			commit("RESET_CART");

			return {
				orderNumber: orderNumber,
				createdOrder: createdOrder,
				orderlinesResponse: orderlinesResponse,
			};
		},

		async deleteOrderline({ commit, dispatch }, payload) {
			if (payload && payload.id) {
				let orderline_id = payload.id;
				let orderNumber = null;
				if (payload.number) {
					orderNumber = payload.number;
				}
				await directus.items("orderlines").deleteOne(orderline_id);
				dispatch("getData");
				/*
				if (orderNumber) {
					let orderlines = await directus.items("orderlines").readByQuery({
						filter: {
							number: {
								_eq: orderNumber,
							},
						},
					});
				}
				if (!orderlines || !orderlines.data || orderlines.data.length == 0) {
					await directus.items("orders").deleteOne(orderline_id);
				}
				*/
			}
			return null;
		},

		async deletePurchaseOrder({ commit, dispatch }, payload) {
			if (payload && payload.id) {
				return await directus
					.items("purchases")
					.deleteOne(payload.id)
					.then((response) => {
						dispatch("getData");
					});
			}
			return null;
		},

		async getOrderNumber({ commit }) {
			let year = moment().year();
			let orders = await directus.items("orders").readByQuery({
				limit: 1,
				sort: ["-number"],
			});
			if (orders.data.length > 0) {
				if (orders.data[0].number) {
					let yearFound = Number(String(orders.data[0].number).substring(0, 4));
					if (year > yearFound) {
						return year * 1000000 + 1;
					} else {
						return Number(orders.data[0].number) + 1;
					}
				}
			}
			return year * 1000000 + 1;
		},

		async getOrderlineNumber({ commit }) {
			let orderlines = await directus.items("orderlines").readByQuery({
				limit: 1,
				sort: ["-number"],
			});
			if (orderlines.data.length > 0) {
				if (orderlines.data[0].number) {
					return Number(orderlines.data[0].number) + 1;
				}
			}
			return 1000000 + 1;
		},

		async getPurchaseNumber({ commit }) {
			let purchases = await directus.items("purchases").readByQuery({
				limit: 1,
				sort: ["-number"],
			});
			if (purchases.data.length > 0) {
				if (purchases.data[0].number) {
					return Number(purchases.data[0].number) + 1;
				}
			}
			return 1000000 + 1;
		},

		async purchaseOrderline({ commit, dispatch, state }, payload) {
			let purchaseNumber = await dispatch("getPurchaseNumber");
			let quantity = payload.quantity ? payload.quantity : 1;
			let rawmaterial_id = payload.rawmaterial && payload.rawmaterial.id ? payload.rawmaterial.id : null;
			let supplier_id = payload.rawmaterial && payload.rawmaterial.supplier ? payload.rawmaterial.supplier : payload.orderline && payload.orderline.supplier ? payload.orderline.supplier : null;
			let orderline_id = payload.orderline && payload.orderline.id ? payload.orderline.id : null;
			let sapcode = payload.rawmaterial && payload.rawmaterial.sapcode ? payload.rawmaterial.sapcode : payload.orderline && payload.orderline.sapcode ? payload.orderline.sapcode : null;
			let description = payload.rawmaterial && payload.rawmaterial.name ? payload.rawmaterial.name : payload.orderline && payload.orderline.description ? payload.orderline.description : null;
			let packaging = payload.rawmaterial && payload.rawmaterial.packaging ? payload.rawmaterial.packaging : payload.orderline && payload.orderline.packaging ? payload.orderline.packaging : null;
			let weight = payload.rawmaterial && payload.rawmaterial.weight ? payload.rawmaterial.weight : payload.orderline && payload.orderline.weight ? payload.orderline.weight : null;
			let costplace = payload.costplace ? payload.costplace : "";

			let purchaseOrder = await directus.items("purchases").createOne({
				number: purchaseNumber,
				date: new Date(),
				quantity: quantity,
				rawmaterial: rawmaterial_id,
				supplier: supplier_id,
				orderline: orderline_id,
				sapcode: sapcode,
				description: description,
				packaging: packaging,
				weight: weight,
				costplace: costplace,
			});
			return purchaseOrder;
		},

		async purchaseCustomNonstock({ commit }, payload) {
			await directus.items("purchases").createOne({
				...payload,
			});
		},
		async submitReceived({ commit, dispatch }, payload) {
			let purchaseLine = await directus.items("purchases").readOne(payload.id);
			let newQuantity = payload.quantity_received;
			if (purchaseLine && purchaseLine.quantity_received) {
				newQuantity = purchaseLine.quantity_received + payload.quantity_received;
			}
			payload.quantity_received = newQuantity;
			await directus.items("purchases").updateOne(payload.id, payload);
		},
		async createStock({ commit }, payload) {
			//console.log(payload);
			let newCreatedStock = await directus.items("stock").createOne({
				date_in: payload.dateIn,
				quantity: payload.quantity_received,
				rawmaterial: payload.rawmaterialId,
				purchase: payload.id,
				comments: payload.comments,
			});

			//console.log(newCreatedStock);
		},

		async saveOrderlineNotes({ commit, dispatch, state }, payload) {
			directus
				.items("orderlines")
				.updateOne(payload.orderline_id, {
					notes: payload.notes,
				})
				.then((response) => {
					return response;
				});
		},
	},
	mutations: {
		SET_AUTHENTICATION(state, payload) {
			state.isAuthenticated = payload;
		},
		SET_USER(state, payload) {
			state.user = payload;
		},
		SET_RAWMATERIALS(state, payload) {
			state.data.rawmaterials = payload.data;
		},
		SET_FILES(state, payload) {
			state.data.files = payload.data;
		},
		SET_RAWMATERIALS_GHS(state, payload) {
			state.data.ghs = payload.data;
		},
		SET_GHS(state, payload) {
			state.data.ghs = payload.data;
		},
		SET_HAZARDSTATEMENTS(state, payload) {
			state.data.hazardstatements = payload.data;
		},
		SET_MILLINGROOMS(state, payload) {
			state.data.millingrooms = payload.data;
		},
		SET_SUPPLIERS(state, payload) {
			state.data.suppliers = payload.data;
		},
		SET_SUPPLIERS(state, payload) {
			state.data.suppliers = payload.data;
		},
		SET_STOCK(state, payload) {
			state.data.stock = payload.data;
		},

		SET_PURCHASES(state, payload) {
			state.data.purchases = payload.data;
		},
		ADD_TO_CART(state, payload) {
			state.cart.push(payload);
		},
		REMOVE_FROM_CART(state, payload) {
			let tempArray = state.cart.filter((item) => (item.id && item.id !== payload.id) || item.sapcode !== payload.sapcode);
			state.cart = tempArray;
		},
		SET_DELIVERYMETHODS(state, payload) {
			state.deliverymethods = payload.data;
		},
		SET_ORDERS(state, payload) {
			state.orders = payload.data;
		},
		SET_ORDERLINES(state, payload) {
			state.orderlines = payload.data;
		},
		SET_DELIVERIES(state, payload) {
			state.deliveries = payload.data;
		},
		RESET_CART(state, payload) {
			state.cart = [];
		},
		CHANGE_CART_ITEM_QUANTITY(state, payload) {
			state.cart = payload;
		},
		SET_ORDER_FOR_SUPPLIER(state, payload) {
			state.orderforsupplier = payload;
		},
	},
	//plugins: [createPersistedState()],
	plugins: [vuexLocal.plugin],
});

export default store;
export { directus };
