var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('div',{staticClass:"table__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('DataTable',{ref:"dt",staticClass:"p-datatable-sm p-datatable-striped boxshadow",attrs:{"dataKey":"stock_id","paginator":false,"rows":20,"responsiveLayout":"scroll","value":_vm.stock_expired,"showGridlines":"","filters":_vm.filters,"filterDisplay":"row"},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex mx-4"},[(_vm.checkPermission('purchase_write'))?_c('div',[_c('Button',{attrs:{"icon":"pi pi-external-link","label":"Export"},on:{"click":function($event){return _vm.exportCSV($event)}}})],1):_vm._e()])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"table__footer"},[_c('strong',[_vm._v("For questions please contact:")]),_vm._v(" RDA Warehouse | "),_c('strong',[_vm._v("E:")]),_vm._v(" "),_c('a',{attrs:{"href":"mailto:SR0734@ppg.com"}},[_vm._v(" SR0734@ppg.com")]),_vm._v(" |"),_c('strong',[_vm._v(" P:")]),_vm._v(" +31 20 407 53 12")])]},proxy:true}])},[_c('Column',{key:"sapcode",attrs:{"field":"sapcode","header":"SAP","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.sapcode)+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
var filterCallback = ref.filterCallback;
return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('Column',{key:"champcode",attrs:{"field":"champcode","header":"Champ","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.champcode)+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
var filterCallback = ref.filterCallback;
return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('Column',{key:"name",attrs:{"field":"name","header":"Name","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.name)+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
var filterCallback = ref.filterCallback;
return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('Column',{key:"packaging",attrs:{"field":"packaging","header":"Packaging","sortable":""}}),_c('Column',{key:"weight",attrs:{"field":"weight","header":"Weight kg per package","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [(data.weight)?_c('div',[_vm._v(_vm._s(parseFloat(data.weight).toFixed(2))+" kg")]):_vm._e()]}}])}),(_vm.checkPermission('stock_read'))?_c('Column',{attrs:{"field":"date_expiration","header":"Expired","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [(data.date_expiration)?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(data.date_expiration,"DD MMMM YYYY"))+" ")]):_vm._e()]}}],null,false,1817000524)}):_vm._e(),(_vm.checkPermission('stock_read'))?_c('Column',{attrs:{"field":"stockSum","header":"On stock","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.stockSum)+" ")]}}],null,false,1977928751)}):_vm._e(),(_vm.checkPermission('stock_read'))?_c('Column',{key:"stock_min",attrs:{"field":"stock_min","header":"Min stock","sortable":""}}):_vm._e(),(_vm.checkPermission('stock_read'))?_c('Column',{key:"stock_max",attrs:{"field":"stock_max","header":"Max stock","sortable":""}}):_vm._e(),_c('Column',{key:"location",attrs:{"field":"location","header":"Location Warehouse","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.location)+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
var filterCallback = ref.filterCallback;
return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('Column',{key:"orderfrom",attrs:{"field":"supplier","header":"Order from","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [(_vm.suppliers.find(function (o) { return o.id === data.supplier; }))?_c('div',[_vm._v(" "+_vm._s(_vm.suppliers.find(function (o) { return o.id === data.supplier; }).code)+" ")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }