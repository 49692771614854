<template>
	<div class="table">
		<div class="table__title">
			{{ title }}
		</div>
		<DataTable dataKey="stock_id" :paginator="false" ref="dt" :rows="20" responsiveLayout="scroll" class="p-datatable-sm p-datatable-striped boxshadow" :value="stock_expired" showGridlines :filters.sync="filters" filterDisplay="row">
			<template #header>
				<div class="flex mx-4">
					<div v-if="checkPermission('purchase_write')">
						<Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
					</div>
				</div>
			</template>
			<Column field="sapcode" header="SAP" key="sapcode" sortable>
				<template #body="{ data }"> {{ data.sapcode }} </template>
				<template #filter="{ filterModel, filterCallback }">
					<InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
				</template>
			</Column>
			<Column field="champcode" header="Champ" key="champcode" sortable>
				<template #body="{ data }">
					{{ data.champcode }}
				</template>
				<template #filter="{ filterModel, filterCallback }">
					<InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
				</template>
			</Column>
			<Column field="name" header="Name" key="name" sortable>
				<template #body="{ data }">
					{{ data.name }}
				</template>
				<template #filter="{ filterModel, filterCallback }">
					<InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
				</template>
			</Column>

			<Column field="packaging" header="Packaging" key="packaging" sortable> </Column>
			<Column field="weight" header="Weight kg per package" key="weight" sortable>
				<template #body="{ data }">
					<div v-if="data.weight">{{ parseFloat(data.weight).toFixed(2) }} kg</div>
				</template>
			</Column>
			<Column v-if="checkPermission('stock_read')" field="date_expiration" header="Expired" sortable>
				<template #body="{ data }">
					<div v-if="data.date_expiration">
						{{ data.date_expiration | moment("DD MMMM YYYY") }}
					</div>
				</template>
			</Column>
			<Column v-if="checkPermission('stock_read')" field="stockSum" header="On stock" sortable>
				<template #body="{ data }">
					{{ data.stockSum }}
				</template>
			</Column>
			<Column v-if="checkPermission('stock_read')" field="stock_min" header="Min stock" key="stock_min" sortable> </Column>
			<Column v-if="checkPermission('stock_read')" field="stock_max" header="Max stock" key="stock_max" sortable> </Column>
			<Column field="location" header="Location Warehouse" key="location" sortable>
				<template #body="{ data }">
					{{ data.location }}
				</template>
				<template #filter="{ filterModel, filterCallback }">
					<InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" />
				</template>
			</Column>
			<Column field="supplier" header="Order from" key="orderfrom" sortable>
				<template #body="{ data }">
					<div v-if="suppliers.find((o) => o.id === data.supplier)">
						{{ suppliers.find((o) => o.id === data.supplier).code }}
					</div>
				</template>
			</Column>

			<template #footer>
				<div class="table__footer"><strong>For questions please contact:</strong> RDA Warehouse | <strong>E:</strong> <a href="mailto:SR0734@ppg.com"> SR0734@ppg.com</a> |<strong> P:</strong> +31 20 407 53 12</div>
			</template>
		</DataTable>
	</div>
</template>

<script>
import { directus } from "../store";
import { mapState } from "vuex";
import moment from "moment";

import { FilterMatchMode, FilterOperator } from "primevue/api/";

export default {
	name: "rawmaterials",
	components: {},
	data() {
		return {
			title: "Expired materials",
			filters: {
				sapcode: {
					value: null,
					matchMode: FilterMatchMode.CONTAINS,
				},
				champcode: {
					value: null,
					matchMode: FilterMatchMode.CONTAINS,
				},
				name: {
					value: null,
					matchMode: FilterMatchMode.CONTAINS,
				},
				location: {
					value: null,
					matchMode: FilterMatchMode.CONTAINS,
				},
			},

			selectedMaterial: {
				name: "",
				sap: "",
				packaging: "",
				stock: [
					{
						quantity: 0,
					},
				],
			},
		};
	},
	created() {},
	async mounted() {
		await this.getData();
	},
	methods: {
		getData() {
			//console.log(this.$store);
			this.$store.dispatch("getData");
		},

		exportCSV() {
			this.$refs.dt.exportCSV();
		},
	},
	computed: {
		...mapState({
			stock_expired: (state) => {
				let stock_expired = [];
				if (state.data.stock && state.data.stock && state.data.stock.length > 0) {
					for (let i = 0; i < state.data.stock.length; i++) {
						let rawmaterial_obj = _.find(JSON.parse(JSON.stringify(state.data.rawmaterials)), { id: state.data.stock[i].rawmaterial });
						if (rawmaterial_obj && rawmaterial_obj.expiration_days && state.data.stock[i].date_in) {
							let date_expiration = moment(state.data.stock[i].date_in).add(rawmaterial_obj.expiration_days, "days");
							rawmaterial_obj.stock = "xxx";

							if (moment(date_expiration).isBefore(moment())) {
								stock_expired.push({
									isExpired: true,
									date_expiration: date_expiration.format("YYYY-MM-DD"),
									stock_id: state.data.stock[i].id,
									rawmaterial_id: rawmaterial_obj.id,
									...rawmaterial_obj,
								});
							}
						}
					}
					console.log("stock", state.data.stock);
					console.log("stock_expired", stock_expired);
					return stock_expired;
				}
			},
			suppliers: (state) => state.data.suppliers,
		}),
	},
};
</script>

<style lang="scss">
.table {
	padding: 20px;
	&__title {
		color: #24222f;
		text-align: left;
		font-weight: bold;
		margin: 20px 0;
		font-size: 28px;
	}
	&__dialog {
		text-align: left;
	}
	&__footer {
		text-align: left;
	}
}
</style>
